import React, { useEffect, useState } from "react";
import { baseUrl } from "../../config";
import Header from "../shared/header";
import Spinner from "../shared/spinner";

import "./style.scss";

const GraphOverView = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState();

    const getData = () => {
        setLoading(true);
        setError(false);
        fetch(`${baseUrl}api/article/value-graph-overview`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (data.ok) {
                    setData(data);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                // setError(error);
            });
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <Spinner />;
    return (
        <div className="overview">
            <Header />
            {error && (
                <div className="error">
                    <h5>{error}</h5>
                </div>
            )}

            {data && (
                <>
                    <h2>Value Graph Overview</h2>
                    <div className="container-md">
                        <div className="row align-items-start">
                            <div className="col">
                                <h4>{data.trainDataCount}</h4>
                                <p>TRAIN DATA COUNT</p>
                            </div>
                            <div className="col">
                                <h4>{data.valueOverview.max}</h4>
                                <p>Value Max</p>
                            </div>
                            <div className="col">
                                <h4>{data.valueOverview.mean}</h4>
                                <p>Value Mean</p>
                            </div>
                            <div className="col">
                                <h4>{data.valueOverview.min}</h4>
                                <p>Value Min</p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <h4>{data.startOverview.max}</h4>
                                <p>Start Max</p>
                            </div>
                            <div className="col">
                                <h4>{data.startOverview.mean}</h4>
                                <p>Start Mean</p>
                            </div>
                            <div className="col">
                                <h4>{data.startOverview.min}</h4>
                                <p>Start Min</p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <h4>{data.endOverview.max}</h4>
                                <p>end Max</p>
                            </div>
                            <div className="col">
                                <h4>{data.endOverview.mean}</h4>
                                <p>end Mean</p>
                            </div>
                            <div className="col">
                                <h4>{data.endOverview.min}</h4>
                                <p>end Min</p>
                            </div>
                        </div>

                        <div className="row align-items-center">
                            <div className="col">
                                <h4>{data.xLengthOverview.max}</h4>
                                <p>xLength Max</p>
                            </div>
                            <div className="col">
                                <h4>{data.xLengthOverview.mean}</h4>
                                <p>xLength Mean</p>
                            </div>
                            <div className="col">
                                <h4>{data.xLengthOverview.min}</h4>
                                <p>xLength Min</p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default GraphOverView;
