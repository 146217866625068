import React, { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import { Fetcher } from "../../utils";
import { parse } from "query-string";
import { useHistory, Redirect } from "react-router-dom";

import Header from "../shared/header";
import PrimaryArticle from "../GraphData/PrimaryArticle";
import GraphPlotting from "../NewGraph/Graph";

import "../Home/style.scss";

const GetArticle = () => {
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const [articlesData, setArticlesData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [removePoint, setRemovePoint] = useState(false);
    const [zoom, setZoom] = useState(false);

    //history function to push url
    const history = useHistory();

    //Get article id from url
    const query = parse(history.location.search);
    const articleID = query.q;

    const handleLink = (url) => {
        history.push(`/new-graph?q=${url}`);
    };

    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleRemove = () => {
        setRemovePoint(!removePoint);
    };

    //Function to get article data
    const getArticleData = async (e) => {
        setEditMode(false);
        setArticlesData([]);
        setData(null);
        setLoading(true);
        setError(false);
        const result = await Fetcher(`${baseUrl}api/article/get-article`, {
            _id: articleID,
        });
        if (result) {
            const { ok, valueGraph, message } = result;
            setLoading(false);
            if (message) {
                setError(result.message);
            }
            if (ok) {
                let data = [...valueGraph];
                data[0].lock = true;
                data[data.length - 1].lock = true;
                setArticlesData(data);
                setData(result);
            }
        }
    };

    //Function to update article data
    const handleUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(false);
        fetch(`${baseUrl}api/article/update-pricing-curve`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                _id: data._id,
                valueGraph: articlesData,
                releaseDate: data.releaseTime,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.ok) {
                    setError("Article Updated");
                    setTimeout(() => {
                        setError(null);
                    }, 2000);
                } else {
                    if (result.message) {
                        setError(result.message);
                    }
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        getArticleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    if (loading) return <div>Loading....</div>;

    return (
        <div className="master-page get-article">
            <Header />
            {error && (
                <div className="error">
                    <h5>{error}</h5>
                </div>
            )}
            {articlesData.length > 0 && (
                <div className="row">
                    <div className="row-inner">
                        <GraphPlotting
                            graphData={articlesData}
                            zoom={zoom}
                            page="getArticle"
                            // releaseTime={data.releaseTime}
                        />
                        {data && (
                            <>
                                <button onClick={handleEditMode} className="btn btn-edit-mode">
                                    Edit mode
                                </button>

                                <div
                                    className={editMode ? "buttons-home show" : "buttons-home hide"}
                                    style={{ maxWidth: "920px" }}
                                >
                                    <div className="row">
                                        <button
                                            onClick={handleRemove}
                                            className="btn remove-button"
                                        >
                                            {!removePoint
                                                ? "Click to enable remove point"
                                                : "Click to disable remove point"}
                                        </button>
                                        <button
                                            onClick={() => setZoom(!zoom)}
                                            className="btn info-button"
                                        >
                                            {zoom ? "Enabled " : "Enable Zoom In"}
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={handleUpdate} className="btn fetch-button">
                                            {isLoading ? "Saving" : "Save Data"}
                                        </button>
                                    </div>
                                </div>

                                <PrimaryArticle
                                    primaryArticle={data.article}
                                    handleLink={handleLink}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            <footer></footer>
        </div>
    );
};

export default GetArticle;
