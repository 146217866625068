import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./compenents/Routes";

import "./assets/scss/App.scss";

const App = () => {
    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
};

export default App;
