import React from "react";
import PropTypes from "prop-types";

import "../Home/style.scss";

/**
 * Similar Links component for home page
 * @prop {Array} articlesData - articles data
 * @prop {Function} handleLink - Function to take user to graph page
 * @prop {Boolean} linksdisabled - Boolean weather link are disabled or not
 * @prop {Boolean} multiGraph - if true user is on multi graph page
 */
const SimilarLinks = ({ articlesData, handleLink, linksdisabled, multiGraph = false }) => {
    const handleDate = (_date) => {
        let date = new Date(_date);
        date = date.toDateString();

        return date;
    };

    const handleArticleLink = (id) => {
        if (linksdisabled) {
            return;
        } else {
            handleLink(id);
        }
    };
    return (
        <div className="home-similar-links">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {!multiGraph && <th>Release Time</th>}
                        {!multiGraph && <th>URL</th>}
                        {!multiGraph && <th>Similarity Score</th>}
                        {multiGraph && <th>ID</th>}
                        <th>Color</th>
                    </tr>
                </thead>

                <tbody>
                    {articlesData.map((data, index) => (
                        <tr key={index}>
                            {!multiGraph && (
                                <td className="curve-time">
                                    {handleDate(data?.releaseDate) || "--"}
                                </td>
                            )}
                            {!multiGraph && (
                                <td
                                    className="curve-url"
                                    onClick={() => handleArticleLink(data._id)}
                                >
                                    {data.url || "--"}
                                </td>
                            )}
                            {!multiGraph && (
                                <td className="curve-score">
                                    {(data?.similarScore * 100).toFixed(2) || "--"}
                                </td>
                            )}
                            {multiGraph && (
                                <td
                                    onClick={() => handleArticleLink(data._id)}
                                    className="curve-url"
                                >
                                    {data?._id}
                                </td>
                            )}
                            <td className="curve-label">
                                <span
                                    className="td-label"
                                    style={{
                                        backgroundColor: `${data.color}`,
                                        height: "10px",
                                        width: "20px",
                                        display: "inline-block",
                                        border: " 1px solid #000",
                                    }}
                                ></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

SimilarLinks.propTypes = {
    articlesData: PropTypes.array,
    handleLink: PropTypes.func.isRequired,
    linksdisabled: PropTypes.bool,
};

export default SimilarLinks;
