import React from "react";
import PropTypes from "prop-types";

/**
 * Component shows article details table in graph page
 * @prop {Object} articleData - articleData contains article details
 * @prop {String} releaseTime - releaseTime of article
 */
const ArticleData = ({ articleData, releaseTime }) => {
    let date = "--";
    if (releaseTime) {
        date = new Date(releaseTime);
        date = date.toDateString();
    }

    return (
        <div className="article-data">
            <div className="table-outer">
                <h4>Article Data</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Author</th>
                            <th>Url</th>
                            <th>Date Published</th>
                            <th>Title</th>
                            <th>Keywords</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{articleData?.author || "--"}</td>
                            <td>{articleData?.url || "--"}</td>
                            <td>{date}</td>
                            <td>{articleData?.title || "--"}</td>
                            <td>
                                {articleData?.keywords && (
                                    <>
                                        {articleData?.keywords?.map((keyword, index) => (
                                            <span key={index}>
                                                {keyword} <i>,</i>
                                            </span>
                                        ))}
                                    </>
                                )}
                                {!articleData?.keywords && "--"}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ArticleData.propTypes = {
    articleData: PropTypes.object,
    articleLink: PropTypes.string.isRequired,
    releaseTime: PropTypes.string,
};

export default ArticleData;
