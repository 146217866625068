import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { parse } from "query-string";
import { baseUrl } from "../../config";
import Datetime from "react-datetime";

import ArticleData from "./ArticleData";
import GraphPlotting from "./Graph";
import Spinner from "../shared/spinner";

import "./style.scss";
import "react-datetime/css/react-datetime.css";
import Header from "../shared/header";

const Graph = () => {
    const [removePoint, setRemovePoint] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [graphData, setGraphData] = useState(
        [] || [
            { time: 0, value: 1000 },
            { time: 180000, value: 1000 },
        ]
    );
    const [similarLinks, setSimilarLinks] = useState([]);
    const [articleData, setArticleData] = useState(null);
    const [releaseTime, setReleaseTime] = useState(null);
    const [showData, setShowData] = useState({
        graph: false,
        links: false,
    });

    const history = useHistory();

    const query = parse(history.location.search);

    const articleLink = query.q;

    // Function to toggle edit mode
    const handleEditMode = () => {
        setEditMode(!editMode);
    };

    //Function to handle remove points in graph
    const handleRemove = () => {
        setRemovePoint(!removePoint);
    };

    //Function to take user to get article page and show that article details
    const handleLink = (id) => {
        history.push(`/get-article?q=${id}`);
        getArticleData();
    };

    //Function to get random color
    const getRandomColor = (index) => {
        const colors = [
            "#d85e5e",
            "#dd34d0c7",
            "#f4b138",
            "#573da7c9",
            "#fd7b23",
            "#b9c72a",
            "#32f7c0d9",
            "#385499",
            "#943939",
            "#12bb31",
        ];

        return colors[index];
    };

    //Function to show graph or similar link when click upon
    const handleShowData = (type) => {
        if (type === "value") {
            setShowData({ ...showData, graph: !showData.graph });
        } else if (type === "similar") {
            setShowData({ ...showData, links: !showData.links });
        }
    };

    //Function to update article
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        fetch(`${baseUrl}api/article/update-new-article`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                url: articleData.url,
                valueGraph: graphData,
                releaseDate: releaseTime,
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.ok) {
                    setError("Article Updated");
                } else {
                    if (result.message) {
                        setError(result.message);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    //Function to get article data
    const getArticleData = () => {
        setLoading(true);
        setArticleData(null);
        setError(false);
        fetch(`${baseUrl}api/article/new-article`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify({ url: articleLink }),
        })
            .then((res) => res.json())
            .then((result) => {
                const { ok, releaseDate, similarLinks, message, articleDetails } = result;
                if (!ok) {
                    setError(message);
                } else {
                    let data = [...result.predictedValueGraph];
                    data[0].lock = true;
                    data[data.length - 1].lock = true;
                    setGraphData(data);
                    setArticleData(articleDetails);
                    setReleaseTime(releaseDate);
                    similarLinks.forEach((element, index) => {
                        element.color = getRandomColor(index);
                    });
                    setSimilarLinks(similarLinks);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                // setError(error);
            });
    };

    useEffect(() => {
        getArticleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    if (loading) return <Spinner />;

    return (
        <div className="graph-page">
            <Header />
            {error && (
                <div className="error">
                    <h5>{error}</h5>
                </div>
            )}
            {articleData && (
                <div className="inner">
                    <div className="graph">
                        {showData.graph && (
                            <>
                                <GraphPlotting
                                    graphData={graphData}
                                    releaseTime={releaseTime}
                                    similarLinksData={similarLinks}
                                    showSimilarData={showData.links}
                                />
                                <div className="edit-button">
                                    <button
                                        onClick={handleEditMode}
                                        className="btn btn-secondary btn-edit-mode"
                                    >
                                        Edit mode
                                    </button>
                                </div>

                                <div
                                    // className="buttons mb-2 mt-1"
                                    className={editMode ? "buttons show" : "buttons hide"}
                                >
                                    <div className="row">
                                        <button
                                            onClick={handleRemove}
                                            className="btn remove-button"
                                        >
                                            {!removePoint
                                                ? "Click to enable remove point"
                                                : "Click to disable remove point"}
                                        </button>
                                        <button
                                            onClick={() => setZoom(!zoom)}
                                            className="btn info-button"
                                        >
                                            {zoom ? "Enabled " : "Enable Zoom In"}
                                        </button>
                                        <Datetime
                                            className="release-time"
                                            onChange={(date) => {
                                                setReleaseTime(date.format("YYYY-MM-DDTHH:mm"));
                                            }}
                                            initialValue={new Date(releaseTime)}
                                        />
                                    </div>
                                    <div>
                                        <button onClick={handleSubmit} className="btn fetch-button">
                                            {loading ? "Saving" : "Save Data"}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        <ArticleData
                            articleData={articleData}
                            articleLink={articleLink}
                            releaseTime={releaseTime}
                        />
                    </div>
                    <div>
                        <div className="show-btn">
                            <button
                                type="button"
                                onClick={() => handleShowData("value")}
                                className="btn btn-primary"
                            >
                                Value Graph Predicted
                            </button>
                            <button
                                type="button"
                                onClick={() => handleShowData("similar")}
                                className="btn btn-primary show-similar-link-data"
                            >
                                Similar Links Predicted
                            </button>
                        </div>
                        {showData.links && (
                            <table className="table table-bordered similar-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Similar Links</th>
                                        <th scope="col">Similar Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {similarLinks.map((link, index) => (
                                        <tr key={index} onClick={() => handleLink(link._id)}>
                                            <td className="curve-label">
                                                <span
                                                    className="td-label"
                                                    style={{
                                                        backgroundColor: `${link.color}`,
                                                        height: "10px",
                                                        width: "20px",
                                                        display: "inline-block",
                                                        border: " 1px solid #000",
                                                    }}
                                                ></span>
                                            </td>
                                            <td className="active">{link.url}</td>
                                            <td>{(link.similarScore * 100).toFixed(2)}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            )}
            <footer>
                <p>Footer</p>
            </footer>
        </div>
    );
};

export default Graph;
