import React, { useState } from "react";
import { baseUrl } from "../../config";
import { useHistory, Redirect } from "react-router-dom";

import "./style.scss";

const Auth = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState(null);

    const history = useHistory();

    // handle input on change function
    const onChange = (field, e) => {
        let _form = { ...form };
        _form[field] = e.target.value;
        setForm(_form);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        fetch(`${baseUrl}api/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form),
        })
            .then((res) => res.json())
            .then(({ ok, accessToken, refreshToken, message }) => {
                setLoading(false);
                if (!ok) {
                    return setError(message);
                }
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                history.push("/");
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    if (localStorage.accessToken && localStorage.refreshToken) return <Redirect to="/" />;

    return (
        <div id="auth">
            {/* <h2>Sign In</h2>
            {error && <p className="alert-danger">{error}</p>}
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="column">
                    <label>Email:</label>
                    <input
                        value={form.email}
                        type="email"
                        onChange={(e) => onChange("email", e)}
                        placeholder="Enter email"
                    />
                </div>
                <div className="column">
                    <label>Password:</label>
                    <input
                        value={form.password}
                        type="password"
                        onChange={(e) => onChange("password", e)}
                        placeholder="Enter password"
                    />
                </div>
                <button
                    disabled={loading}
                    className={loading ? "disabled" : "enabled"}
                    onSubmit={(e) => handleSubmit(e)}
                >
                    Submit
                </button>
            </form> */}

            <main className="form-signin login">
                {error && <p className="alert-danger">{error}</p>}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <h1 className="h3 mb-3 fw-normal">Log In</h1>

                    <div className="form-floating">
                        <input
                            type="email"
                            value={form.email}
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={(e) => onChange("email", e)}
                        />
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            value={form.password}
                            onChange={(e) => onChange("password", e)}
                        />
                        <label for="floatingPassword">Password</label>
                    </div>

                    <button
                        disabled={loading}
                        className={loading ? "disabled" : "enabled"}
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </main>
        </div>
    );
};

export default Auth;
