import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./style.scss";

/**
 * Component GetMasterData shows input box at home page
 */
const GetMasterData = () => {
    const [articleLink, setArticleLink] = useState({
        id: "",
        ids: [],
        url: "",
    });

    const history = useHistory();

    // const handleLink = (id) => {
    //     history.push(`/get-article?q=${id}&article=get`);
    // };

    const setArticleUrl = (field, value) => {
        let _articleLink = { ...articleLink };
        if (field === "ids") {
            let _value = value;
            _articleLink[field] = _value.split(" ");
        } else {
            _articleLink[field] = value;
        }
        setArticleLink(_articleLink);
    };

    const handleSubmit = (e, type) => {
        e.preventDefault();
        if (type === "similar") {
            history.push(`/search?q=${articleLink.id}`);
        } else if (type === "new") {
            history.push(`/new-graph?q=${articleLink.url}`);
        } else if (type === "overview") {
            history.push("/overview");
        } else if (type === "multiple") {
            history.push(`/graphs?q=${articleLink.ids}`);
        }
    };

    return (
        <div className="home-page">
            <div className="row bb graph-data">
                <h5>Search article by Query:</h5>
                <form onSubmit={(e) => handleSubmit(e, "similar")}>
                    <input
                        value={articleLink.id}
                        onChange={(e) => setArticleUrl("id", e.target.value)}
                        placeholder="Search by Query"
                    />
                    <button
                        onSubmit={(e) => handleSubmit(e, "similar")}
                        className={articleLink.id.length < 1 && "disabled"}
                        disabled={articleLink.id.length < 1}
                    >
                        Similar Links
                    </button>
                </form>
                {/* <button
                    className={
                        articleLink.id.length < 1 ? "btn submit-btn-2 disabled" : "btn submit-btn-2"
                    }
                    onClick={() => handleLink(articleLink.id)}
                    disabled={!articleLink.id}
                >
                    Graph-Tool page
                </button> */}
            </div>

            <div className="row bb new-article">
                <h5>Get new article by URL:</h5>
                <form onSubmit={(e) => handleSubmit(e, "new")}>
                    <input
                        value={articleLink.url}
                        onChange={(e) => setArticleUrl("url", e.target.value)}
                        placeholder="Search by url"
                    />
                    <button
                        className={articleLink.url.length < 1 && "disabled"}
                        onSubmit={(e) => handleSubmit(e, "new")}
                        disabled={articleLink.url.length < 1}
                    >
                        New Article
                    </button>
                </form>
            </div>

            <div className="row bb multiple-article">
                <h5>Get multiple articles by IDs:</h5>
                <form onSubmit={(e) => handleSubmit(e, "multiple")}>
                    <input
                        value={articleLink.ids}
                        onChange={(e) => setArticleUrl("ids", e.target.value)}
                        placeholder="Search by IDs"
                    />
                    <button
                        className={articleLink.ids.length < 1 && "disabled"}
                        onSubmit={(e) => handleSubmit(e, "multiple")}
                        disabled={articleLink.ids.length < 1}
                    >
                        Submit
                    </button>
                </form>
            </div>

            <div className="row overview">
                <h5>Get Overall Details:</h5>
                <button className="btn btn-overview" onClick={(e) => handleSubmit(e, "overview")}>
                    Value Graph OverView
                </button>
                {/* <h5>Get details of Graphs Overview</h5> */}
            </div>
        </div>
    );
};

export default GetMasterData;
