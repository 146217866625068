import React, { useState } from "react";
import { baseUrl } from "../../../config";
import { Redirect } from "react-router-dom";

import "../style.scss";
import Header from "../../shared/header";

const Signup = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        email: "",
        password: "",
        role: "user",
    });
    const [error, setError] = useState(null);

    // handle input on change function
    const onChange = (field, e) => {
        let _form = { ...form };
        let value = e.target.value;
        if (field === "role") {
            if (value === "on" && form.role === "user") {
                value = "admin";
            } else {
                value = "user";
            }
        }
        _form[field] = value;
        setForm(_form);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        fetch(`${baseUrl}api/signup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
            body: JSON.stringify(form),
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (!result.ok) {
                    setError(result.message);
                } else {
                    setError("User is created");
                }
                setTimeout(() => {
                    setError(null);
                }, 2000);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    return (
        <div id="auth">
            <Header />
            {/* <h2>Create new User</h2>
            {error && <p className="alert-danger">{error}</p>}
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="column">
                    <label>Email:</label>
                    <input
                        value={form.email}
                        type="email"
                        onChange={(e) => onChange("email", e)}
                        placeholder="Enter email"
                    />
                </div>
                <div className="column">
                    <label>Password:</label>
                    <input
                        value={form.password}
                        type="password"
                        onChange={(e) => onChange("password", e)}
                        placeholder="Enter password"
                    />
                </div>
                <div className="row">
                    <input type="checkbox" onChange={(e) => onChange("role", e)} />
                    <p>Admin</p>
                </div>
                <button
                    disabled={loading}
                    className={loading ? "disabled" : "enabled"}
                    onSubmit={(e) => handleSubmit(e)}
                >
                    Sign Up
                </button>
            </form> */}
            <main className="form-signin">
                {error && <p className="alert-danger">{error}</p>}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <h1 className="h3 mb-3 fw-normal">Create new user</h1>

                    <div className="form-floating">
                        <input
                            type="email"
                            value={form.email}
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={(e) => onChange("email", e)}
                        />
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            value={form.password}
                            onChange={(e) => onChange("password", e)}
                        />
                        <label for="floatingPassword">Password</label>
                    </div>

                    <div className="row">
                        <input type="checkbox" onChange={(e) => onChange("role", e)} />
                        <p>Admin</p>
                    </div>
                    <button
                        disabled={loading}
                        className={loading ? "disabled" : "enabled"}
                        type="submit"
                    >
                        Submit
                    </button>
                </form>
            </main>
        </div>
    );
};

export default Signup;
