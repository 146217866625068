import React from "react";
import { Redirect } from "react-router-dom";

import Header from "../shared/header";
import GetMasterData from "./getData";

import "./style.scss";

const Home = () => {
    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    return (
        <div className="master-page">
            <Header />
            <GetMasterData />
        </div>
    );
};

export default Home;
