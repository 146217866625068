import React, { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import { Fetcher } from "../../utils";
import { parse } from "query-string";
import { useHistory, Redirect } from "react-router-dom";

import Graphs from "../GraphData/Graphs";
import Header from "../shared/header";

import "./style.scss";
import SimilarLinks from "../GraphData/Links";

const MultiGraph = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [articlesData, setArticlesData] = useState([]);

    //history function to push url
    const history = useHistory();

    //Get article id from url
    const query = parse(history.location.search);

    let IdAarray = query.q;
    IdAarray = IdAarray.split(",");

    const handleLink = (id) => {
        history.push(`/get-article?q=${id}`);
    };

    //Function to get random color
    const getRandomColor = (index) => {
        const colors = [
            "#d85e5e",
            "#dd34d0c7",
            "#f4b138",
            "#573da7c9",
            "#fd7b23",
            "#b9c72a",
            "#32f7c0d9",
            "#385499",
            "#943939",
            "#12bb31",
        ];

        return colors[index];
    };

    //Function to get article data
    const getArticleData = async (e) => {
        setArticlesData([]);
        setLoading(true);
        setError(false);
        const result = await Fetcher(`${baseUrl}api/article/get-value-graphs`, {
            articleIdList: IdAarray,
        });
        if (result) {
            const { message, ok, valueGraphList } = result;
            setLoading(false);
            if (!ok) {
                setError(message);
            } else {
                valueGraphList.forEach((element, index) => {
                    element.color = getRandomColor(index);
                });
                setArticlesData(valueGraphList);
            }
        }
    };
    useEffect(() => {
        getArticleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    if (loading) return <div>Loading....</div>;

    return (
        <div className="multi-graph">
            <Header />
            {error && (
                <div className="error">
                    <h5>{error}</h5>
                </div>
            )}
            {articlesData.length > 0 && (
                <div className="inner">
                    <Graphs articlesData={articlesData} graphMaxValue={1850} />
                    <SimilarLinks
                        handleLink={handleLink}
                        articlesData={articlesData}
                        multiGraph={true}
                    />
                </div>
            )}
        </div>
    );
};

export default MultiGraph;
