import React from "react";
import { Switch, Route } from "react-router-dom";

// components
import Homepage from "../Home";
// import GraphPage from "../GraphData";
import NewArticlePage from "../NewGraph";
// import TestSimilarLinks from "../TestSimilarLinks";
import Auth from "../Auth";
import Signup from "../Auth/Signup";
import GraphOverView from "../Overview";
import GetArticle from "../GetArticle";
import MultiGraph from "../MultiGraphs";
import SearchQuery from "../Query";

import "bootstrap/dist/css/bootstrap.css";
import CandleChart from "../WebTest";

let Routes = () => {
    return (
        <Switch>
            <Route path="/login" exact={true} component={Auth} />
            <Route path="/signup" exact={true} component={Signup} />
            <Route path="/" exact={true} component={Homepage} />
            <Route path="/search" exact={true} component={SearchQuery} />
            {/* <Route path="/graph" exact={true} component={GraphPage} /> */}
            <Route path="/new-graph" exact={true} component={NewArticlePage} />
            <Route path="/overview" exact={true} component={GraphOverView} />
            <Route path="/get-article" exact={true} component={GetArticle} />
            <Route path="/graphs" exact={true} component={MultiGraph} />
            <Route path="/test" exact={true} component={CandleChart} />
        </Switch>
    );
};

export default Routes;
