/**
 * Function to fetch
 * @param {string} url - url for api
 * @param {Object} data - data for api
 */
export const Fetcher = (url, data) => {
    return new Promise(async (resolve, reject) => {
        const payload = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(data),
        };

        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");

        if (accessToken) payload.headers.Authorization = "Bearer " + accessToken;

        let response;
        let result;
        let error;
        try {
            response = await fetch(url, payload);
        } catch (err) {
            console.log("called", err);
            error = err;
        }

        // // if unauthrorized, refresh token
        if (error === "Unauthorized") {
            try {
                console.log("called this unauth");
                result = await Fetcher("/token/refresh", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                });
            } catch (err) {
                // refresh token has expired, logout
                return reject();
            }
            localStorage.setItem("accessToken", result.access_token);
            localStorage.setItem("refreshToken", result.refresh_token);
            const newResponse = await Fetcher(url, data);
            return newResponse;
        }
        if (error) return reject(error);
        const json = await response.json();
        resolve(json);
    });
};
