import React from "react";
import { useHistory } from "react-router-dom";

import "./style.scss";

const Header = () => {
    const history = useHistory();

    const handleLogout = () => {
        localStorage.clear();
        history.push("/login");
    };
    return (
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom header">
            <a
                href="/"
                className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
            >
                <span className="fs-4">Qontento - Valuation Engine</span>
            </a>

            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a href="/" className="nav-link">
                        Home
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/signup" className="nav-link">
                        Create User
                    </a>
                </li>
                <li className="nav-item">
                    <button onClick={handleLogout} className="btn btn-danger">
                        Logout
                    </button>
                </li>
                <li className="nav-item"></li>
            </ul>
        </header>
    );
};

export default Header;
