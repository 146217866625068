import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import { getData } from "./data2";
import LedgerChart from "./Ledger";
import Filter from "./Filter";

import "./style.scss";

const CandleChart = () => {
    const [data, setData] = useState(getData());
    const [options, setOptions] = useState();
    const [zoomData, setZoomData] = useState("0-100");
    const [chartFilter, setChartFilter] = useState("6H");
    const [chart, setChart] = useState();

    const upColor = "#91C3B3";
    const upBorderColor = "#3D9970";
    const downColor = "#F29796";
    const downBorderColor = "#FF4136";

    useEffect(() => {
        let zoom;
        if (chartFilter === "1H") {
            zoom = "98-100";
        } else if (chartFilter === "6H") {
            zoom = "80-100";
        } else if (chartFilter === "12H") {
            zoom = "70-100";
        } else if (chartFilter === "1D") {
            zoom = "60-100";
        } else if (chartFilter === "2D") {
            zoom = "30-100";
        } else if (chartFilter === "LT") {
            zoom = "0-100";
        }

        setZoomData(zoom);

        if (chart) {
            const _zoom = zoom.split("-").map((val) => parseFloat(val, 10));
            chart.dispatchAction({ type: "dataZoom", start: _zoom[0], end: _zoom[1] });
        }

        setData(getData());
    }, [chartFilter, chart]);

    const onChartReadyCallback = (e) => {
        setChart(e);
    };

    useEffect(() => {
        if (chart) {
            chart.on("datazoom", (e) => {
                if (e.batch) {
                    const zoom = { ...e.batch[e.batch.length - 1] };
                    setZoomData(`${zoom.start}-${zoom.end}`);
                }
            });
        }
    }, [chart, zoomData]);

    useEffect(() => {
        if (data) {
            setOptions({
                tooltip: {
                    trigger: "item",
                    axisPointer: {
                        type: "cross",
                    },
                },
                grid: {
                    left: "10%",
                    right: "10%",
                    bottom: "10%",
                },
                xAxis: {
                    type: "category",
                    data: data.map((item) => item[0]),
                    scale: true,
                    boundaryGap: true,
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: "#999",
                        },
                    },
                    splitLine: { show: false },
                    splitNumber: 10,
                    min: "dataMin",
                    max: "dataMax",
                    axisLabel: {
                        color: "#fff",
                    },
                },
                yAxis: {
                    scale: true,
                    splitArea: {
                        show: false,
                    },
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            opacity: 0.1,
                        },
                    },
                    axisLine: {
                        show: true,
                        onZero: true,
                        lineStyle: {
                            color: "#999",
                            width: 1,
                        },
                    },
                },
                dataZoom: [
                    {
                        type: "inside",
                        filterMode: "weakFilter",
                        start: 0,
                        end: 100,
                    },
                ],
                series: [
                    {
                        // name: "Article name here",
                        type: "candlestick",
                        data: data.map((item) => item.slice(1)),
                        itemStyle: {
                            color: upColor,
                            color0: downColor,
                            borderColor: upBorderColor,
                            borderColor0: downBorderColor,
                        },

                        markLine: {
                            symbol: ["none", "none"],
                            data: [
                                [
                                    {
                                        name: "from lowest to highest",
                                        type: "min",
                                        valueDim: "lowest",
                                        symbol: "arrow",
                                        symbolSize: 0,
                                        label: {
                                            show: false,
                                        },
                                        // zoom: (e) => console.log(" zoom featurte", e),
                                        emphasis: {
                                            label: {
                                                show: false,
                                            },
                                        },
                                    },
                                    {
                                        type: "max",
                                        valueDim: "highest",
                                        symbol: "arrow",
                                        symbolSize: 0,
                                        label: {
                                            show: false,
                                        },
                                        lineStyle: {
                                            width: 0,
                                            color: "red",
                                        },
                                        emphasis: {
                                            label: {
                                                show: false,
                                            },
                                        },
                                    },
                                ],
                            ],
                        },
                    },
                ],
            });
        }
    }, [data]);

    if (!options) return <div> Loading....</div>;

    return (
        <div className="chart">
            <Filter chartFilter={chartFilter} setChartFilter={setChartFilter} />
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        width: "85%",
                        height: "50%",
                        maxWidth: "800px",
                    }}
                    onChartReady={onChartReadyCallback}
                />
            )}

            <div className="ledger">
                <p>Cohorts Ownership/Dividends:</p>
                <LedgerChart zoomData={zoomData} />
            </div>
        </div>
    );
};

export default CandleChart;
