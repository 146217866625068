import React, { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import { Fetcher } from "../../utils";
import { parse } from "query-string";
import { useHistory, Redirect } from "react-router-dom";

import Header from "../shared/header";
import Graphs from "../GraphData/Graphs";
import SimilarLinks from "../GraphData/Links";

import "../Home/style.scss";

const SearchQuery = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [articlesData, setArticlesData] = useState([]);
    const [graphMaxValue, setGraphMaxValue] = useState(1850);

    //history function to push url
    const history = useHistory();

    //Get article id from url
    const query = parse(history.location.search);
    const articleID = query.q;

    const handleLink = (url) => {
        history.push(`/get-article?q=${url}`);
    };

    //Function to get random color
    const getRandomColor = (index) => {
        const colors = [
            "#d85e5e",
            "#dd34d0c7",
            "#f4b138",
            "#573da7c9",
            "#fd7b23",
            "#b9c72a",
            "#32f7c0d9",
            "#385499",
            "#943939",
            "#12bb31",
        ];

        return colors[index];
    };

    //Function to get article data
    const getArticleData = async (e) => {
        setGraphMaxValue(1850);
        setArticlesData([]);
        setLoading(true);
        setError(false);

        const result = await Fetcher(`${baseUrl}api/graphtool/search-by-query`, {
            query: articleID,
        });
        if (result) {
            const { similar_links, max_value } = result;
            setLoading(false);
            similar_links.forEach((element, index) => {
                element.color = getRandomColor(index);
            });
            setGraphMaxValue(max_value);
            setArticlesData(similar_links);
        }
    };

    useEffect(() => {
        getArticleData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!localStorage.accessToken && !localStorage.refreshToken) return <Redirect to="/login" />;

    if (loading) return <div>Loading....</div>;

    return (
        <div className="master-page">
            <Header />
            {error && (
                <div className="error">
                    <h5>{error}</h5>
                </div>
            )}
            {articlesData.length > 0 && (
                <div className="row">
                    <div className="row-inner">
                        <Graphs articlesData={articlesData} graphMaxValue={graphMaxValue} />
                    </div>
                    <SimilarLinks handleLink={handleLink} articlesData={articlesData} />
                </div>
            )}

            <footer></footer>
        </div>
    );
};

export default SearchQuery;
