import React from "react";

import "../Home/style.scss";

const PrimaryArticle = ({ primaryArticle, handleLink, linkdisabled }) => {
    const handleArticleLink = (url) => {
        if (linkdisabled) {
            return;
        } else {
            handleLink(url);
        }
    };
    return (
        <div className="primary-article">
            <table className="table">
                <thead>
                    <tr>
                        <th>Publisher</th>
                        <th>Title</th>
                        <th>URL</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{primaryArticle.publisher || "--"}</td>
                        <td>{primaryArticle.title || "--"}</td>
                        <td
                            className="no-data-url"
                            onClick={() => handleArticleLink(primaryArticle.url)}
                        >
                            {primaryArticle.url || "--"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PrimaryArticle;
