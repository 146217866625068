import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import moment from "moment-timezone";

/**
 * Component shows graph in graph page
 * @prop {Array} graphData - graphData contains graph points
 * @prop {String} releaseTime - releaseTime of article
 * @prop {Array} similarLinksData - array of data for similar links
 * @prop {Boolean} showSimilarData - for new article page to show/hide similar links graph
 * @prop {String} page - in which page is user at, it allows configurations to add points in --
 * lines as the graph size is different for every page
 */

const GraphPlotting = ({ graphData, releaseTime, page, similarLinksData, showSimilarData }) => {
    const extentRef = useRef();
    const variables = useRef({
        zoom: false,
        removePoint: false,
        editMode: false,
        similarLinks: false,
    });

    const removeGraph = () => {
        d3.selectAll(".line-chart > *").remove();
    };

    const drawChart = (data) => {
        const svgWidth = 940,
            svgHeight = 420;
        const margin = { top: 15, right: 40, bottom: 30, left: 50 };
        const width = svgWidth - margin.left - margin.right;
        const height = svgHeight - margin.top - margin.bottom;

        const svg = d3.select(".line-chart").attr("width", svgWidth).attr("height", svgHeight);

        const g = svg
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let x = d3.scaleLinear().rangeRound([0, width]);

        let x1 = d3.scaleLinear().rangeRound([0, width]);

        let y = d3.scaleLinear().rangeRound([height, 0]);

        let y2 = d3.scaleLinear().rangeRound([height, 0]);

        const drag = d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended);

        // Define the div for the tooltip
        const tooltip = d3.select(".tooltip");

        // gridlines in x axis function
        function make_x_gridlines() {
            return d3.axisBottom(x).ticks(9);
        }

        // gridlines in y axis function
        function make_y_gridlines() {
            return d3.axisLeft(y).ticks(9);
        }

        function dragstarted(d) {
            // d3.select(this).raise().classed('active', true);
            d3.select(this).attr("stroke", "black").attr("fill", "#000").attr("r", 7.5);
            tooltip.transition().duration(200).style("opacity", 0);
            d3.select(this).raise().classed("active", true);
        }

        function dragged(event, d) {
            let yValues = [];
            let xValues = [];
            d3.select(".y-left")
                .selectAll(".tick")
                .each(function (d) {
                    yValues.push(d);
                });
            d3.select(".x-bottom")
                .selectAll(".tick")
                .each(function (d) {
                    xValues.push(d);
                });
            const minY = Math.min(...yValues);
            const maxY = Math.max(...yValues);
            const minX = Math.min(...xValues);
            const maxX = Math.max(...xValues);

            const posX = event.x - margin.left;
            const posY = event.y - margin.top;

            if (x.invert(posX) < minX || x.invert(posX) > maxX) {
                return;
            }

            if (y.invert(posY) < minY || y.invert(posY) > maxY) {
                return;
            }
            if (d.lock === true) {
                d3.select(this)
                    .raise()
                    .attr("cy", (d.y = posY));
                d.value = y.invert(posY);
            } else {
                d3.select(this)
                    .raise()
                    .attr("cx", (d.x = posX))
                    .attr("cy", (d.y = posY));
                d.time = x.invert(posX);
                d.value = y.invert(posY);
            }
            // Remove the main line
            svg.select(".line").remove();
            // Add the main line
            g.append("path")
                .datum(data)
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("stroke-width", 3.5)
                .attr("d", line)
                .on("click", handleClick)
                .attr("cursor", "pointer")
                .attr("clip-path", "url(#clip)");
        }

        function dragended(event, data) {
            // Delete x and y values to prevent margin error when dragging dot again
            delete data.x;
            delete data.y;
            d3.select(this).attr("stroke", null).attr("fill", "red").attr("r", 5.5);
            d3.select(this).classed("active", false);
        }

        x.domain([0, 180000]);

        x1.domain([0, 180000]);
        y.domain([0, 1850]);
        y2.domain([0, 1850]);

        svg.append("defs")
            .append("svg:clipPath")
            .attr("id", "clip")
            .append("svg:rect")
            .attr("width", width)
            .attr("height", height)
            .attr("x", 0)
            .attr("y", 0);

        let line = d3
            .line()
            .x(function (d) {
                return x(d.time);
            })
            .y(function (d) {
                return y(d.value);
            });
        // .curve(d3.curveCardinal);

        // add the X gridlines
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .attr("opacity", "0.15")
            .call(make_x_gridlines().ticks(30).tickSize(height).tickFormat(""));

        // add the Y gridlines
        svg.append("g")
            .attr("class", "grid")
            .attr("transform", `translate(${margin.left}, ${margin.top})`)
            .attr("opacity", "0.15")
            .call(make_y_gridlines().ticks(30).tickSize(-width).tickFormat(""));

        //Add bottom x-axis
        g.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(
                d3
                    .axisBottom(x)
                    .ticks(20)
                    .tickFormat((e) => `${e}s`)
            )
            .attr("class", "x-bottom")
            .attr("font-size", "12px")
            .append("text")
            .attr("fill", "#000")
            .attr("transform", "translate(35, 10)")
            .attr("y", 5)
            .attr("dy", "1em")
            .attr("text-anchor", "end")
            .text("Time");

        // Add top x-axis
        if (releaseTime) {
            g.append("g")
                .attr("transform", "translate(0, -22)")
                .call(
                    d3
                        .axisBottom(x1)
                        .ticks(20)
                        .tickFormat((e) => {
                            return moment(releaseTime + "Z")
                                .tz("UTC")
                                .add(e, "seconds")
                                .format("HH:mm");
                        })
                )
                .attr("class", "x-top")
                .attr("font-size", "12px");
        }

        //Add y-axis
        g.append("g")
            .call(
                d3
                    .axisLeft(y)
                    .ticks(20)
                    .tickFormat((e) => `${e}Q`)
            )
            .attr("class", "y-left")
            .attr("font-size", "12px")
            // .attr("transform", `translate(0, 0)`)
            .append("text")
            .attr("fill", "#000")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", "0.71em")
            .attr("text-anchor", "end")
            .text("Price");

        //Add right y-axis
        g.append("g")
            .call(
                d3
                    .axisRight(y2)
                    .ticks(20)
                    .tickFormat((e) => `$${Math.round(e / 91.07)}`)
            )
            .attr("font-size", "12px")
            .attr("class", "y-right")
            .attr("transform", `translate(${width}, 0)`)
            .append("text")
            .attr("fill", "#000")
            .attr("y", 6)
            .attr("dy", "0.71em")
            .attr("text-anchor", "end")
            .text("Price");

        //Add similar link lines
        if (variables.current.similarLinks) {
            similarLinksData.forEach((item) => {
                g.append("path")
                    .datum(item.valueGraph)
                    .attr("class", "similar-line")
                    .attr("fill", "none")
                    .attr("stroke", item.color)
                    .attr("stroke-linejoin", "round")
                    .attr("stroke-linecap", "round")
                    .style("stroke-dasharray", "4, 4")
                    .attr("stroke-width", 1.8)
                    .attr("d", line)
                    .attr("cursor", "pointer")
                    .attr("clip-path", "url(#clip)");
            });
        }

        //Add the line
        g.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round")
            .attr("stroke-width", 4.5)
            .attr("d", line)
            .on("click", handleClick)
            .attr("cursor", "pointer")
            .attr("clip-path", "url(#clip)");

        // Add brushing
        let brush = d3
            .brush() // Add the brush feature using the d3.brush function
            .extent([
                [0, 0],
                [width, height],
            ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
            .on("end", updateChart);

        // A function that set idleTimeOut to null
        let idleTimeout;
        function idled() {
            idleTimeout = null;
        }

        /**
         *
         * Set value if zoom is set to false
         */
        if (extentRef.current) {
            x.domain([x.invert(extentRef.current[0][0]), x.invert(extentRef.current[1][0])]);
            y.domain([y.invert(extentRef.current[1][1]), y.invert(extentRef.current[0][1])]);
            y2.domain([y.invert(extentRef.current[1][1]), y.invert(extentRef.current[0][1])]);
            svg.select(".x-bottom").call(
                d3
                    .axisBottom(x)
                    .ticks(10)
                    .tickFormat((e) => `${e}s`)
            );
            svg.select(".y-left").call(
                d3
                    .axisLeft(y)
                    .ticks(15)
                    .tickFormat((e) => `${e}Q`)
            );
            svg.select(".y-right").call(
                d3
                    .axisRight(y2)
                    .ticks(15)
                    .tickFormat((e) => `$${(e / 91.07).toFixed(1)}`)
            );
            svg.select(".line").attr(
                "d",
                d3
                    .line()
                    .x(function (d) {
                        return x(d.time);
                    })
                    .y(function (d) {
                        return y(d.value);
                    })
            );
            svg.selectAll(".dot").remove();
            //Add the points
            svg.selectAll("myCirlces")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("cx", function (d) {
                    return x(d.time);
                })
                .attr("cy", function (d) {
                    return y(d.value);
                })
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("fill", "red")
                .attr("r", 5.5)
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);

            // Remove similar lines
            svg.selectAll(".similar-line").remove();
            //Add similar link lines
            if (variables.current.similarLinks) {
                similarLinksData.forEach((item) => {
                    g.append("path")
                        .datum(item.valueGraph)
                        .attr("class", "similar-line")
                        .attr("fill", "none")
                        .attr("stroke", item.color)
                        .attr("stroke-linejoin", "round")
                        .attr("stroke-linecap", "round")
                        .style("stroke-dasharray", "4, 4")
                        .attr("stroke-width", 1.8)
                        .attr("d", line)
                        .attr("cursor", "pointer")
                        .attr("clip-path", "url(#clip)");
                });
            }
        }

        /**
         *
         * // A function that update the chart for given boundaries
         */

        function updateChart(event) {
            const extent = event.selection;

            // What are the selected boundaries?

            // If no selection, back to initial coordinate. Otherwise, update X axis domain
            if (!extent) {
                if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
                x.domain([0, 180000]);
                x1.domain([0, 180000]);
                y.domain([0, 1850]);
                y2.domain([0, 1850]);
            } else {
                x.domain([x.invert(extent[0][0]), x.invert(extent[1][0])]);
                x1.domain([x.invert(extent[0][0]), x.invert(extent[1][0])]);
                y.domain([y.invert(extent[1][1]), y.invert(extent[0][1])]);
                y2.domain([y.invert(extent[1][1]), y.invert(extent[0][1])]);
                svg.select(".brush").call(brush.move, null); // This remove the grey brush area as soon as the selection has been done
                extentRef.current = extent;
            }

            // Update axis and line position
            svg.select(".x-bottom")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisBottom(x)
                        .ticks(10)
                        .tickFormat((e) => `${e}s`)
                );

            svg.select(".x-top")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisBottom(x)
                        .ticks(10)
                        .tickFormat((e) =>
                            moment(releaseTime + "Z")
                                .tz("UTC")
                                .add(e, "seconds")
                                .format("HH:mm")
                        )
                );

            // Update y left axis

            svg.select(".y-left")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisLeft(y)
                        .ticks(15)
                        .tickFormat((e) => `${e}Q`)
                );

            svg.select(".y-right")
                .transition()
                .duration(1000)
                // .attr("transform", "translate(0,0)")
                .call(
                    d3
                        .axisRight(y2)
                        .ticks(15)
                        .tickFormat((e) => `$${(e / 91.07).toFixed(1)}`)
                );

            svg.select(".line")
                .transition()
                .duration(800)
                .attr(
                    "d",
                    d3
                        .line()
                        .x(function (d) {
                            return x(d.time);
                        })
                        .y(function (d) {
                            return y(d.value);
                        })
                );

            svg.selectAll(".similar-line")
                .transition()
                .attr(
                    "d",
                    d3
                        .line()
                        .x(function (d) {
                            return x(d.time);
                        })
                        .y(function (d) {
                            return y(d.value);
                        })
                );

            //Remove the points
            svg.selectAll(".dot").remove();
            if (variables.current.editMode) {
                setTimeout(() => {
                    //Add the points
                    svg.selectAll("myCirlces")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr("class", "dot")
                        .attr("cx", function (d) {
                            return x(d.time);
                        })
                        .attr("cy", function (d) {
                            return y(d.value);
                        })
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                        .attr("fill", "red")
                        .attr("r", 5.5)
                        .attr("clip-path", "url(#clip)")
                        .on("mouseover", handleMouseOver)
                        .on("mouseout", handleMouseOut);
                }, 800);
            }
        }

        /**
         *
         * // If user double click, reinitialize the chart
         */
        svg.on("dblclick", function () {
            extentRef.current = null;
            x.domain([0, 180000]);
            x1.domain([0, 180000]);
            y.domain([0, 1850]);
            y2.domain([0, 1850]);

            svg.select(".x-bottom")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisBottom(x)
                        .ticks(20)
                        .tickFormat((e) => `${e}s`)
                );

            svg.select(".x-top")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisBottom(x1)
                        .ticks(20)
                        .tickFormat((e) =>
                            moment(releaseTime + "Z")
                                .tz("UTC")
                                .add(e, "seconds")
                                .format("HH:mm")
                        )
                );

            svg.select(".y-left")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisLeft(y)
                        .ticks(20)
                        .tickFormat((e) => `${e}Q`)
                );

            svg.select(".y-right")
                .transition()
                .duration(1000)
                .call(
                    d3
                        .axisRight(y2)
                        .ticks(20)
                        .tickFormat((e) => `$${Math.round(e / 91.07)}`)
                );

            svg.select(".line")
                .transition()
                .attr(
                    "d",
                    d3
                        .line()
                        .x(function (d) {
                            return x(d.time);
                        })
                        .y(function (d) {
                            return y(d.value);
                        })
                );

            if (variables.current.editMode) {
                svg.selectAll(".similar-line")
                    .transition()
                    .attr(
                        "d",
                        d3
                            .line()
                            .x(function (d) {
                                return x(d.time);
                            })
                            .y(function (d) {
                                return y(d.value);
                            })
                    );
            } else {
                svg.selectAll(".similar-line")
                    .transition()
                    .attr(
                        "d",
                        d3
                            .line()
                            .x(function (d) {
                                return x(d.time);
                            })
                            .y(function (d) {
                                return y(d.value);
                            })
                            .curve(d3.curveCatmullRom)
                    );
            }

            // Remove all points
            svg.selectAll(".dot").remove();

            if (variables.current.editMode) {
                setTimeout(() => {
                    svg.selectAll(".dot").remove();
                    //Add the points
                    svg.selectAll("myCirlces")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr("class", "dot")
                        .attr("cx", function (d) {
                            return x(d.time);
                        })
                        .attr("cy", function (d) {
                            return y(d.value);
                        })
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                        .attr("fill", "red")
                        .attr("r", 5.5)
                        .on("mouseover", handleMouseOver)
                        .on("mouseout", handleMouseOut);
                }, 800);
            }
        });

        // Function to enable drag if RemovePoint false
        function handleMouseOver(event, d) {
            if (variables.current.zoom) {
                d3.select(this).attr("cursor", "not-allowed");
                return;
            }
            // Use D3 to select element, change color and size

            d3.select(this).attr("fill", "orange").attr("r", 7.5);
            //Show tooltip
            tooltip.transition().duration(200).style("opacity", 0.9);
            tooltip
                .html(`time:${Math.round(d.time / 3600)}H , value:${Math.round(d.value)}`)
                .style("left", event.clientX - 40 + "px")
                .style("top", event.clientY - 38 + "px");

            if (variables.current.removePoint) {
                d3.select(this).attr("cursor", "pointer").on("click", handleRemove);
            } else {
                d3.select(this).attr("cursor", "move").call(drag);
            }
        }

        // Function disable hover styles
        function handleMouseOut(event, d) {
            if (variables.current.zoom) {
                d3.select(this).attr("cursor", "not-allowed");
                return;
            }
            // Use D3 to select element, change color and size
            d3.select(this).attr("fill", "red").attr("r", 5.5);

            if (variables.current.removePoint) {
                d3.select(this).attr("cursor", "pointer").on("click", handleRemove);
            } else {
                d3.select(this).attr("cursor", "move").call(drag);
            }

            tooltip
                .transition()
                .duration(0)
                .style("opacity", 0)
                .style("left", 0 + "px")
                .style("top", 0 + "px");
        }

        // Create Event Handlers for mouse
        function handleClick(event, data) {
            let pointData = {
                time: x.invert(event.x - 68),
                value: y.invert(event.y - 112),
            };

            if (page === "getArticle") {
                pointData = {
                    time: x.invert(event.x - 48),
                    value: y.invert(event.y - 113),
                };
            }

            let index = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].time > pointData.time) {
                    break;
                } else {
                    index = i;
                }
            }
            data.splice(index + 1, 0, pointData);

            //Remove the curve
            svg.select(".line").remove();
            //Add the line
            g.append("path")
                .datum(data)
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("stroke-width", 3.5)
                .attr("d", line)
                .on("click", handleClick)
                .attr("cursor", "pointer")
                .attr("clip-path", "url(#clip)");

            //Remove the points
            svg.selectAll(".dot").remove();
            //Add the points
            svg.selectAll("myCirlces")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("cx", function (d) {
                    return x(d.time);
                })
                .attr("cy", function (d) {
                    return y(d.value);
                })
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("fill", "red")
                .attr("r", 5.5)
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);
        }

        setTimeout(() => {
            // Function to zoom in-out
            d3.select(".info-button").on("click", (event) => {
                variables.current.zoom = !variables.current.zoom;
                if (variables.current.zoom) {
                    g.append("g").attr("class", "brush").call(brush);
                } else {
                    d3.select("g.brush").remove();
                }
            });

            // Function to edit mode
            d3.select(".btn-edit-mode").on("click", (event) => {
                variables.current.editMode = !variables.current.editMode;
                if (variables.current.editMode) {
                    //Add the points
                    svg.selectAll(".line")
                        .transition()
                        .attr(
                            "d",
                            d3
                                .line()
                                .x(function (d) {
                                    return x(d.time);
                                })
                                .y(function (d) {
                                    return y(d.value);
                                })
                        );
                    //Remove the points
                    svg.selectAll(".dot").remove();
                    //Add the points
                    svg.selectAll("myCirlces")
                        .data(graphData)
                        .enter()
                        .append("circle")
                        .attr("class", "dot")
                        .attr("cx", function (d) {
                            return x(d.time);
                        })
                        .attr("cy", function (d) {
                            return y(d.value);
                        })
                        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                        .attr("fill", "red")
                        .attr("r", 5.5)
                        .on("mouseover", handleMouseOver)
                        .on("mouseout", handleMouseOut);
                } else {
                    //Remove the points
                    svg.selectAll(".dot").remove();
                    svg.selectAll(".line")
                        .transition()
                        .attr(
                            "d",
                            d3
                                .line()
                                .x(function (d) {
                                    return x(d.time);
                                })
                                .y(function (d) {
                                    return y(d.value);
                                })
                                .curve(d3.curveCatmullRom)
                        );
                }
            });

            // Function to toggle remove point
            d3.select(".remove-button").on("click", (event) => {
                variables.current.removePoint = !variables.current.removePoint;
            });

            // Function to toggle similar links data
            d3.select(".show-similar-link-data").on("click", (event) => {
                variables.current.similarLinks = !variables.current.similarLinks;
            });
        }, 200);

        // Function to remove the point
        function handleRemove(event, data) {
            if (data.lock) return;
            tooltip.transition().duration(200).style("opacity", 0);
            let index = 0;
            for (let i = 0; i < graphData.length; i++) {
                if (graphData[i].time === data.time) {
                    index = i;
                    graphData.splice(index, 1);
                    break;
                } else {
                    index = i;
                }
            }
            //Remove the curve
            svg.select(".line").remove();
            //Add the curve
            g.append("path")
                .datum(graphData)
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-linejoin", "round")
                .attr("stroke-linecap", "round")
                .attr("stroke-width", 3.5)
                .attr("d", line)
                .on("click", handleClick)
                .attr("cursor", "pointer")
                .attr("clip-path", "url(#clip)");

            //Remove the points
            svg.selectAll(".dot").remove();
            //Add the points
            svg.selectAll("myCirlces")
                .data(graphData)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("cx", function (d) {
                    return x(d.time);
                })
                .attr("cy", function (d) {
                    return y(d.value);
                })
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
                .attr("fill", "red")
                .attr("r", 5.5)
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);
        }
    };

    useEffect(() => {
        removeGraph();
        drawChart(graphData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSimilarData]);

    return (
        <div>
            <svg className="line-chart"></svg>
            <div className="tooltip"></div>
        </div>
    );
};

GraphPlotting.propTypes = {
    graphData: PropTypes.array.isRequired,
    releaseTime: PropTypes.string,
};

export default GraphPlotting;
