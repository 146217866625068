import React from "react";

import "./style.scss";

const Filter = ({ chartFilter, setChartFilter }) => {
    return (
        <div className="chart-filter">
            <h3>Performance</h3>
            <p>Performance Siraj, Harshal lead RCB’s shift in dynamics</p>

            <div className="filter">
                <ul>
                    <li
                        onClick={() => setChartFilter("1H")}
                        className={chartFilter === "1H" ? "active" : ""}
                    >
                        1H
                    </li>
                    <li
                        onClick={() => setChartFilter("6H")}
                        className={chartFilter === "6H" ? "active" : ""}
                    >
                        6H
                    </li>
                    <li
                        onClick={() => setChartFilter("12H")}
                        className={chartFilter === "12H" ? "active" : ""}
                    >
                        12H
                    </li>
                    <li
                        onClick={() => setChartFilter("1D")}
                        className={chartFilter === "1D" ? "active" : ""}
                    >
                        1D
                    </li>
                    <li
                        onClick={() => setChartFilter("2D")}
                        className={chartFilter === "2D" ? "active" : ""}
                    >
                        2D
                    </li>
                    <li
                        onClick={() => setChartFilter("LT")}
                        className={chartFilter === "LT" ? "active" : ""}
                    >
                        LT
                    </li>
                </ul>
                <ul>
                    <li>Y Axis - Price in QMV</li>
                    <li>X Axis - Time </li>
                </ul>
            </div>
        </div>
    );
};

export default Filter;
