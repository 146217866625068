import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import "./style.scss";

const LedgerChart = ({ zoomData }) => {
    const [chart, setChart] = useState();
    const [options, setOptions] = useState();

    let data = [
        [0, 5, 10, "AA"],
        [7, 8, 10, "AB"],
        [10, 15, 10, "A"],
        [16, 18, 10, "B"],
        [19, 20, 10, "C"],
        [22, 24, 10, "c1"],
        [26, 32, 10, "D"],
        [34, 36, 10, "E"],
        [38, 40, 10, "F"],
        [42, 43, 10, "G"],
        [45, 48, 10, "H"],
        [50, 51, 10, "I"],
        [53, 54, 10, "J"],
        [56, 58, 10, "K"],
        [60, 66, 10, "L"],
        [69, 70, 10, "M"],
        [72, 73, 10, "N"],
        [75, 77, 10, "O"],
        [79, 80, 10, "P"],
        [81, 82, 10, "Q"],
        [84, 87, 10, "R"],
        [89, 90, 10, "s"],
        [92, 93, 10, "T"],
        [95, 96, 10, "U"],
        [97, 98, 10, "V"],
        [100, 101, 10, "W"],
        [102, 104, 10, "X"],
        [105.5, 106, 10, "Y"],
        [107, 108, 10, "Z"],
        [109, 110, 10, "1"],
        [111, 112, 10, "2"],
        [114, 115, 10, "3"],
        [117, 119, 10, "4"],
        [121, 122, 10, "5"],
        [124, 125, 10, "6"],
        [127, 128, 10, "7"],
        [130, 131, 10, "8"],
        [133, 135, 10, "9"],
        [136, 138, 10, "10"],
        [140, 141, 10, "11"],
        [143, 144, 10, "12"],
        [146, 147, 10, "13"],
        [149, 150, 10, "14"],
        [152, 153, 10, "15"],
        [155, 156, 10, "16"],
        [158, 159, 10, "17"],
        [161, 162, 10, "18"],
        [164, 165, 10, "19"],
        [167, 168, 10, "20"],
        [170, 171, 10, "21"],
        [173, 174, 10, "22"],
        [176, 177, 10, "22"],
        [179, 180, 10, "22"],
    ];

    data = data.map(function (item, index) {
        return {
            value: item,
            itemStyle: {
                color: "#F07B39",
            },
        };
    });

    const renderItem = (params, api) => {
        // console.log(" \n \n data is \n \n ", api);
        const yValue = api.value(2);
        const start = api.coord([api.value(0), yValue]);
        const size = api.size([api.value(1) - api.value(0), yValue]);
        const style = api.style();
        return {
            type: "rect",
            shape: {
                x: start[0],
                y: start[1],
                width: size[0],
                height: size[1],
            },
            style: style,
        };
    };

    useEffect(() => {
        setOptions({
            tooltip: {
                show: false,
            },
            xAxis: {
                show: false,
            },
            yAxis: {
                show: false,
            },
            grid: {
                left: "1px",
                right: "1px",
                bottom: "0%",
                top: "0%",
            },
            dataZoom: [
                {
                    type: "inside",
                    disabled: true,
                    filterMode: "weakFilter",
                    start: 0,
                    end: 100,
                },
            ],
            series: [
                {
                    type: "custom",
                    renderItem: renderItem,
                    label: {
                        show: false,
                        position: "top",
                    },
                    dimensions: ["from", "to", "profit"],
                    encode: {
                        x: [0, 1],
                        y: 2,
                        tooltip: [0, 1, 2],
                        itemName: 3,
                    },
                    data: data,
                },
            ],
        });
    }, []);

    const onChartReadyCallback = (e) => {
        setChart(e);
    };

    useEffect(() => {
        if (chart) {
            const zoom = zoomData.split("-").map((val) => parseFloat(val, 10));
            chart.dispatchAction({ type: "dataZoom", start: zoom[0], end: zoom[1] });
        }
    }, [chart, zoomData]);

    return (
        <>
            {options && (
                <ReactECharts
                    option={options}
                    style={{
                        width: "100%",
                        height: "50%",
                        maxWidth: "650px",
                    }}
                    onChartReady={onChartReadyCallback}
                />
            )}
        </>
    );
};

export default LedgerChart;
